export const colors = {
	accent: "#EB1536",
	background_ash: "#FCFCFE",
	black: "#222222",
	blue200: "#DDEFFF",
	blue500: "#0D7FE9",
	blue900: "#051B30",
	green200: "#C4EFDF",
	green700: "#158957",
	grey: "#F5F6F8",
	grey100: "#F5F8FA",
	grey200: "#E1E7EC",
	grey300: "#CED6DE",
	grey500: "#8895A7",
	grey600: "#5F6B7A",
	grey700: "#2A2E33",
	grey900: "#3F3E4D",
	orange: "#EB1536",
	orange200: "#FFF0DB",
	orange700: "#E08304",
	peach: "#FFF8F8",
	primary: "#051B30",
	red200: "#FCC5CE",
	red700: "#842432",
	red500: "#D62F4B",
	secondary: "#153243",
	white: "#FFFFFF",
};
