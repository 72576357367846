import PublicFooter from "../../components/layout/PublicFooter";
import PublicHeader from "../../components/layout/PublicHeader";

const Policy = () => {
	return (
		<>
			<PublicHeader />
			<main>
				{/* Banner */}
				<section className="py-20 px-4 bg-[#051B30] text-center mb-16">
					<h1 className="text-white font-semibold text-4xl mb-4">
						Privacy and Policy
					</h1>
					
				</section>
				<section className="px-6 flex flex-col gap-3 items-center ">
					<p className="xl:max-w-[800px] text-gray-800">
						This data privacy, protection and cookie policy describes your privacy rights regarding PredictBeta’s (“we”, “us” or “our”) collection, use, storage, sharing and protection of your personal information. It applies to our online sport prediction platform and all related sites, platforms, services and tools regardless of how you access or use them. </p>
						<p className="xl:max-w-[800px] text-gray-800">This Data Privacy, Protection and Cookie Policy (the “Privacy Policy”) will help you understand how we use your information and what we do with it. However, this Privacy Policy does not apply to services that are not owned or controlled by PredictBeta, including third-party mobile websites, platforms and the services of other PredictBeta’s merchants. Our goal is to handle personal data provided to us in compliance with applicable data privacy and protection laws. This Privacy Policy applies to all forms of systems, operations and processes within the PredictBeta’s environment that involve the collection, storage, use, transmission and disposal of Personal Information.
					</p>
					<p className="xl:max-w-[800px] text-gray-800">We respect the privacy of our online visitors and registered users (the “Users”) as such we will take reasonable steps to protect your information</p>
					<ol className="xl:max-w-[800px] text-gray-800 list-[number] flex flex-col gap-3">
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600">Company Overview</h2>
								<p>PredictBeta Nigeria Limited is a unique sports marketing company that leverages sports prediction entertainment and interactive engagement to drive marketing for its partners. We offer a free monetary-rewarding sports prediction league on a per round/weekly basis on our platform. Our general league is free to play for monetary prizes, while we take a fee of two percent (2%) for our private league offerings.</p>
								<p><b>Note:</b> Winners do not withdraw their winnings directly on our platform. When winners click on "withdraw funds" on PredictBeta, they are redirected to our partner’s platform, Hallabet, to complete the withdrawal process.</p>
								<p>Our primary goal at PredictBeta is to provide entertainment, sports banter and interaction among sports lovers. Like traditional sports leagues, we use this platform to promote sports predictions in Nigeria, allowing users to compete for the top spot at the end of the season and earn money while doing so. We pride ourselves on being Africa’s leading sports prediction community.</p>
								<p>This Privacy Policy is intended to establish certain standards across the operations of the PredictBeta sports prediction platform, operated by PredictBeta Nigeria Limited.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600">Updates, Modifications & Amendments</h2>
								<p>We may need to update, modify or amend our Privacy Policy as our technology evolves and as required by law. We reserve the right to make changes to this Privacy Policy from time to time and notify Users of material changes. The Privacy Policy will take effect from the date stated as the effective date on our platform.</p>
								<p>We advise that you check this page often, referring to the date of the last modification on the page. If a User objects to any of the changes to this Privacy Policy, the User must cease using this platform, or terminate their account if an account has been created. Where such User continues to use the platform or the account created, the User is bound by the terms of this Privacy Policy.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600"> Age Restriction</h2>
								<p>Our sports prediction platform and services are not for children under the age of 18. We do not knowingly collect information from children below 18. If as a parent or guardian, you become aware that your child or ward has provided us with any information without your consent, please contact us through the details provided in this Privacy Policy.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600"> The Information We Collect</h2>
								<h2 className="font-bold text-rose-600">4.1 Personal Information</h2>
								<p>As part of our operations, PredictBeta collects and processes certain types of information (such as name, telephone numbers, email address, physical address, financial information, etc.) of individuals that make them easily identifiable. These individuals include current, past and prospective employees, merchants, suppliers/vendors, customers of merchants, Users and other individuals who PredictBeta communicates or deals with, jointly and/or severally (“Data Subject(s)”).</p>
								<p><b>Note: PredictBeta will never request for your BVN or bank account login details</b></p>
								<p>PredictBeta is firmly committed to complying with applicable data protection laws, regulations, rules and principles to ensure the security of personal information handled by the Company.</p>
								<p>To gain full access to our sports prediction platform and services, you must register a PredictBeta account. When you register for an account, we collect personal information which you voluntarily provide to us. Personal information refers to information relating to an identified person or information that can be used to identify you, (e.g. email address, name, telephone number). It may also include anonymous information that may be linked to you specifically, (e.g. IP address).<b> Also note that, when you sign up on our platform and agree to our terms and conditions, you automatically consent to being registered on our partner's platform, Hallabet.</b></p>
								<h2 className="font-bold text-rose-600">We use your personal information to:</h2>
								<ul className="list-disc ml-3">
									<li>Provide you with the required services.</li>
									<li> Respond to your questions or requests.</li>
									<li>Improve features and sports prediction platform content and analyse data to develop products and services.</li>
									<li>Manage your account.</li>
									<li>Address inappropriate use of our sports prediction platform.</li>
									<li>Prevent, detect and manage risk against fraud and illegal activities using internal and third-party screening tools</li>
									<li>Send you marketing content, newsletters and service updates curated by PredictBeta, however, we will provide you with an option to unsubscribe if you do not want to hear from us again.</li>
									<li>Verify your identity and the information you provide in line with PredictBeta’s statutory obligations using internal and third-party tools.</li>
									<li>Maintain up-to-date records.</li>
									<li>For marketing purposes for our partner, Hallabet (owned by Halla Gaming Company Limited), as well as other third-party partners to PredictBeta.</li>
									<li> Business restructuring considerations such that we may use your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by us about our service users is among the assets to be transferred.</li>
									<li>Resolve disputes that may arise, including investigations by law enforcement or regulatory bodies.</li>
									<li>Any other purpose that we disclose to you while providing services to you.</li>
								</ul>
								<p>With your consent, we may also collect additional Personal Information in other ways including emails, surveys, and other forms of communication. Once you begin using our services through your PredictBeta account, we will keep records of your transactions and collect information on your other activities related to our services. We will not share or disclose your Personal Information with a third party without your consent except as may be required for the purpose of providing you with our services or under applicable law.</p>
								<p>In providing you with the services, we may rely on third-party servers located in foreign jurisdictions from time to time, which as a result, may require the transfer or maintenance of your personally identifiable information on computers or servers in foreign jurisdictions. You consent that we may transfer your data for the purpose of providing you with the services. We will ensure to comply with the requirements of the Nigeria Data Protection Act, 2023 (“NDPA”) and any applicable law for the transfer of personal data.</p>
								<h2 className="font-bold text-rose-600">4.2 Information that we collect from sports prediction platform visitors</h2>
								<p>We do not collect your personal Information when you visit our sports prediction platform. However, so we can monitor and improve our sports prediction platform and services we may collect non-personally identifiable information. We will not share or disclose this information with third parties except as a necessary part of providing our sports prediction platform and services. We may use the information to target advertisements to you.</p>
								<h2 className="font-bold text-rose-600">4.3 Information that we collect from checkout users</h2>
								<p>When you checkout with PredictBeta on a merchant’s sports prediction platform, we collect and store your account information, email address, mobile phone number, billing and shipping address(es). To ensure your account information is kept safe and secure on our servers, we implement access control measures (both physical and virtual), security protocols, policies, and standards, including the use of encryption and firewall technologies in compliance with PCI DSS Requirements, and we implement periodic security updates to ensure our security infrastructures meet industry standards.</p>
								<p>Users will make payments through third-party fintech platforms like Paystack and Flutterwave. We may share your contact information with merchants as part of your purchase details for record purposes. We will not share this information with other third parties except as necessary to provide our sports prediction platform and services. We do not share your bank account details with merchants. Please review your merchant’s privacy policy to understand the privacy policies guiding the merchant you transact with.</p>
								<h2 className="font-bold text-rose-600">4.4 Purpose Limitation</h2>
								<p>PredictBeta collects personal information only for identified purposes and for which consent has been obtained. Such personal information cannot be reused for another purpose that is incompatible with the original purpose, except consent is obtained for such purpose.</p>
								<h2 className="font-bold text-rose-600">4.5 Data Minimization</h2>
								<p>PredictBeta limits personal information collection and usage to data that is relevant, adequate, and necessary for carrying out the purpose for which the data is processed.<br/>
 
PredictBeta will evaluate whether and to what extent the processing of personal information is necessary and where the purpose allows, anonymised data will be used.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600">Two Factor Authentication (“2FA”)</h2>
								<p>2FA is an additional layer of security we have added to your account. When 2FA is enabled, you will be required to enter a One Time Password (OTP) (which is a verification code that we will send to you for authentication purposes) each time you carry out a transaction checkout using PredictBeta on a merchant’s sports prediction platform. While we encourage you to enable this feature on every transaction, you may choose to disable the 2FA feature after your initial enrolment by clicking on the toggle button to disable. However, if you choose to disable this feature, you agree that PredictBeta shall not be liable for any loss, or damages incurred because of your action.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600">6. Cookies</h2>
								<p> 
We use cookies to identify you as a User and make your user experience easier, customise our services, content and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our sports prediction platform. Cookies allow our servers to remember IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities.</p>
<p>Our cookies do not store personal or sensitive information directly; instead, they hold a unique random reference to you. This allows us to recognise you when you visit our site and provide personalised content. Additionally, cookies will be used to process your personal information for marketing purposes for PredictBeta Nigeria Limited and its partners, including Hallabet and other marketing partners.
</p>
<p>You can manage or disable cookies through your browser or device settings. Please note that disabling certain types of cookies may impact your experience on our website and limit the functionality we can provide.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600">How We Protect your Information</h2>
								<p>PredictBeta shall establish adequate controls in order to protect the integrity and confidentiality of personal information, both in digital and physical format and to prevent personal information from being accidentally or deliberately compromised.</p>
								<p>PredictBeta is committed to managing your personal information in line with global industry best practices. We protect your personal information using physical, technical, and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure and alteration. We also use industry-recommended security protocols to safeguard your personal information. Other security safeguards include but are not limited to data encryption, firewalls, and physical access controls to our building and files and only granting access to personal information to only employees who require it to fulfil their job responsibilities. Any personal information processing undertaken by an employee who has not been authorized to carry out such as part of the employee’s legitimate duties is unauthorized.</p>
								<p>Employees may have access to personal information only as is appropriate for the type and scope of the task in question and are forbidden to use personal information for their own private or commercial purposes, to disclose them to unauthorized persons, or to make them available in any other way.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600"> How we share the Personal Information you provide</h2>
								<p>PredictBeta does not sell, trade, or rent personal information to anyone. However, to enable us to render our services to you on our sports prediction platform, we may share your information with trusted third parties. Such third parties include financial institutions, payment processors, verification service providers, sanctions screening entities/persons, identity verification service providers and any third parties that you have directly authorised to receive your personal information. Your personal information may be stored in locations outside the direct control of PredictBeta, for instance, on servers or databases co-located with hosting providers</p>
								<p>We may disclose your personal information in compliance with applicable law or a legal obligation to which we are bound.</p>
								<p>Please note that merchants, sellers, and other Users you buy from or contract with have their privacy policies, and although PredictBeta’s Terms of Use does not allow the other transacting party to use your information for anything other than as authorised by you, PredictBeta is not responsible for their actions, including their information protection practices.</p>
								<p>The use of your information by such third parties will be subject to their privacy policy, which recommend that you carefully review.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600"> Transfer of Personal Information</h2>
								
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600">Third Party Processor within Nigeria</h2>
								<p>PredictBeta may engage the services of third parties in order to process the personal information of Data Subjects collected by us. The processing by such third parties shall be governed by a written contract with PredictBeta to ensure that adequate protection and security measures are put in place by the third party for the protection of personal information under this Privacy Policy.</p>
								<h2 className="font-bold text-rose-600">9.2 Transfer of Personal Information to Foreign Country</h2>
								<p>Where personal information is to be transferred to a country outside Nigeria, PredictBeta shall put adequate measures in place to ensure the security of such personal information. In particular, we shall, among other things, confirm whether the country is on the National Information Technology Development Agency (“NITDA”) White List of Countries with adequate data protection laws and comply with the provisions of the NDPA in making such transfers.</p>
								<p>Transfer of personal information out of Nigeria would be in line with the provisions of the NDPA. PredictBeta will therefore only transfer personal information out of Nigeria on any of the following conditions:</p>
								<ul className="list-disc ml-3">
									<li>The consent of the Data Subject has been obtained;</li>
									<li> The transfer is necessary for the performance of a contract between PredictBeta and the Data Subject or implementation of pre-contractual measures taken at the Data Subject’s request;</li>
									<li>The transfer is necessary to conclude a contract between PredictBeta and a third party in the interest of the Data Subject;</li>
									<li> The transfer is necessary for reasons of public interest;</li>
									<li> The transfer is for the establishment, exercise or defence of legal claims;</li>
									<li>The transfer is necessary to protect the vital interests of the Data Subjects or other persons, where the Data Subject is physically or legally incapable of giving consent.</li>
								</ul>
								<p>PredictBeta will take all necessary steps to ensure that Personal Information is transmitted in a safe and secure manner. Details of the protection given when your personal information is transferred outside Nigeria shall be provided to you upon request.</p>
								<h2 className="font-bold text-rose-600">9.3 Transfer of Personal Information to Business Partners</h2>
								<p>PredictBeta may share the personal information of Data Subjects with our business partners to offer Data Subjects certain products, services, or promotions. Where personal information is transferred to business partners, PredictBeta shall execute a written contract with the business partner(s) to ensure adequate protection and security measures are put in place for the protection of personal information under this Privacy Policy.</p>
								<h2 className="font-bold text-rose-600">9.4 Transfer of Personal Information to Affiliates</h2>
								<p>PredictBeta may transfer the personal information of Data Subjects with its affiliates, in which case PredictBeta will require those affiliates to honour this Privacy Policy. For this Privacy Policy, Affiliates include related companies, joint venture partners, or any other company that we control or that are under common control with us.</p>
								<h2 className="font-bold text-rose-600">9.5 Transfer of Personal Information to Prospective Buyers of the Company</h2>
								<p>PredictBeta may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600"> 10. Grounds for Processing of Personal Information</h2>
								<p>Processing of personal information by PredictBeta shall be lawful if at least one of the following applies:</p>
								<ul className="list-disc ml-3">
									<li>the Data Subject has given consent to the processing of his/her personal information for one or more specific purposes;</li>
									<li> the processing is necessary for the performance of a contract to which the Data Subject is party or to take steps at the request of the Data Subject before entering into a contract;</li>
									<li> processing is necessary for compliance with a legal obligation to which PredictBeta is subject;</li>
									<li> processing is necessary to protect the vital interests of the Data Subject or of another natural person; and</li>
									<li>processing is necessary for the performance of a task carried out in the public interest or in the exercise of an official public mandate vested in PredictBeta.</li>
								</ul>
								<p>In this Privacy Policy, consent means any freely given, specific, informed and unambiguous indication of the Data Subject's wishes by which they, through a statement or a clear affirmative action, signify their agreement to the processing of personal information relating to them.</p>
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600"> Choices and Rights</h2>
								<p>Individuals who have personal information held by PredictBeta are entitled to reach out to exercise the following rights:</p>
								<ul className="list-disc ml-3">
									<li> Right to request for and access their personal information collected and stored. Where data is held electronically in a structured form, such as in a Database, the Data Subject has a right to receive that data in a common electronic format;</li>
									<li> Right to information on their personal information collected and stored;</li>
									<li> Right to object to automated decision-making;</li>
									<li>Right to request rectification and modification of personal information which PredictBeta keeps;</li>
									<li>Right to request for deletion of their data;</li>
									<li> Right to request the movement of data from PredictBeta to a third party; this is the right to the portability of data; and</li>
									<li>Right to object to, and to request that PredictBeta restricts the processing of their information.</li>
								</ul>
								<p>Where a Data Subject wishes to exercise any of the above rights, a request from such Data Subject will be reviewed by PredictBeta’s Data Protection Officer and carried out except as restricted by law or PredictBeta’s statutory obligations. You may decline to provide your personal Information when it is requested by PredictBeta, however, certain services or all the services may be unavailable to you. You may review your account settings and update your personal information directly or by contacting us.</p>
								
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600">The Data that We Retain</h2>
								<p>We will retain your information for as long as your account is active or as needed to provide our services to you, comply with our legal and statutory obligations or verify your information with a financial institution.</p>
								<p>PredictBeta is statutorily obligated to retain the data you provide us with to process transactions, ensure settlements, make refunds, identify fraud and in compliance with laws and regulatory guidelines applicable to us, our banking providers and payment processors.</p>
								<p>Therefore, even after closing your PredictBeta account, we will retain certain personal information and transaction data to comply with these obligations. All personal information shall be destroyed by PredictBeta where possible. For all personal data and records obtained, used and stored by PredictBeta, we shall perform periodical reviews of the data retained to confirm the accuracy, purpose, validity and requirement to retain.</p>
								<p>The length of storage of personal information shall, amongst other things, be determined by:</p>
								<ul className="list-disc ml-3">
									<li> the contract terms agreed between PredictBeta and the Data Subject or as long as it is needed for the purpose for which it was obtained; or</li>
									<li>whether the transaction or relationship has statutory implication or a required retention period; or</li>
									<li>whether there is an express request for deletion of personal data by the Data Subject, provided that such request will only be treated where the Data Subject is not under any investigation which may require PredictBeta to retain such Personal Data or there is no subsisting contractual arrangement with the Data Subject that would require the processing of the personal data; or</li>
									<li>whether PredictBeta has another lawful basis for retaining that information beyond the period for which it is necessary to serve the original purpose.</li>
								</ul>
								
							</div>
						</li>
						<li>
							<div className="flex flex-col gap-3">
								<h2 className="font-bold text-rose-600">Contact PredictBeta’s Data Protection Officer (DPO)</h2>
								<p>If you have any questions relating to this Privacy Policy or would like to find out more about exercising your data protection rights, please reach out to our DPO via our official email: predictbeta@gmail.com</p>
								<p>PredictBeta maintains a data breach procedure to deal with incidents concerning personal information or practices leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal information transmitted, stored or otherwise processed. You may contact our DPO upon becoming aware of any breach of personal information or if your access credentials have been compromised, to enable us to take the necessary steps toward ensuring the security of your personal information or account</p>
								<p>Thank you for entrusting PredictBeta Nigeria Limited with your data.</p>
							</div>
						</li>
					</ol>
				</section>
			</main>
			<PublicFooter />
		</>
	);
};

export default Policy;
